import { useEffect, useRef } from "react";
import ReactModal from "react-modal";
import Styles from "stylesheets/index/RecommendPopup.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/slick.scss";
import closeIcon from "images/recommendo_popup_close.svg";
import presentIcon from "images/recommendo_popup_present.svg";
import { Offer, Media } from "models";
import { v4 as uuidv4 } from "uuid";
import baseAxios from "apis/axios";
import { useHistory } from "hooks/useHistory";
import { useErrorHandler } from "react-error-boundary";
import { ERROR_PATTERN } from "components/common/ErrorBoundary";
import { Link } from "react-router-dom";
import defaultPath from "utiles/path";
import { useChallenging } from "hooks/useChallenging";

interface Color {
  appAreaBgColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}

interface RecommendPopupColor {
  offerListBgColor: string;
  achievementTextColor: string;
  achievementListBgColor: string;
  recommendTextColor: string;
  pointNumberColor: string;
}

interface Props {
  pointIconUrl: string;
  color: Color;
  recommendPopupColor: RecommendPopupColor;
  isOpen: boolean;
  nextpopup: () => void;
  groupId: number;
  totalUserOfferPoint: number;
  RecommendText: string;
  offers: Offer[];
  mediaData: Media;
  AchievementText: string;
  recommendOfferIds;
}

export const RecommendPopup = ({
  groupId,
  offers,
  totalUserOfferPoint,
  color,
  recommendPopupColor,
  AchievementText,
  RecommendText,
  recommendOfferIds,
  mediaData,
  isOpen,
  nextpopup,
}: Props) => {
  const filteredOffers = offers
    .filter((offer) => recommendOfferIds.includes(offer.id))
    .sort((a, b) => recommendOfferIds.indexOf(a.id) - recommendOfferIds.indexOf(b.id));

  // useRef でフラグを保持
  const hasFetched = useRef(false);
  const [historyError, , historyOffers] = useHistory();
  const [challengingError, , challengingOffers] = useChallenging();
  const mergedOffers = [
    ...historyOffers.filter((offer) => offer.isApproved),
    ...challengingOffers.filter((offer) =>
      offer.conversionPoints.some((point) => point.isReceived)
    ),
  ].filter((offer, index, self) => index === self.findIndex((o) => o.id === offer.id));

  const handleError = useErrorHandler();
  const locationQuery: string = location.search ? location.search : "?";

  if (historyError || challengingError) {
    handleError(ERROR_PATTERN.API);
  }

  useEffect(() => {
    if (isOpen && !hasFetched.current) {
      hasFetched.current = true;

      const queries = new URLSearchParams(location.search);
      const suid = queries.get("suid") ?? "";
      const offerIds = filteredOffers.map((offer) => offer.id);
      const traceId = uuidv4();
      // groupIdが0または1の場合、group_idを0に設定
      // 以下APIは分析に使用
      const groupIdValue = groupId === 0 || groupId === 1 ? 0 : groupId;
      const apiUrl = `${process.env.REACT_APP_RECOMMEND_POPUP_ANALYTICS_URL}?trace_id=${traceId}&media_id=${mediaData.id}&media_user_id=${suid}&group_id=${groupIdValue}&offer_1=${offerIds[0]}&offer_2=${offerIds[1]}&offer_3=${offerIds[2]}`;
      baseAxios.get(apiUrl);
    }
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={Styles.overlay}
      shouldFocusAfterRender={false}
      className={Styles["offer-content"]}
      testId="recommendPopup"
      onAfterOpen={() => {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
      }}
      onAfterClose={() => {
        document.body.style.removeProperty("overflow");
        document.documentElement.style.removeProperty("overflow");
        document.body.style.removeProperty("position");
        document.body.style.removeProperty("width");
      }}
      ariaHideApp={false}
    >
      <div className={Styles.lMainContent}>
        <div
          className={Styles.lMainWrap}
          style={{ backgroundColor: recommendPopupColor.achievementListBgColor }}
        >
          <div className={Styles.lMainClose}>
            <img className={Styles["close-icon"]} src={closeIcon} onClick={() => nextpopup()} />
          </div>
          <div className={Styles.lMainMission}>
            <h2 style={{ color: recommendPopupColor.achievementTextColor }}>
              <img src={presentIcon} alt="" />
              {AchievementText}
            </h2>
            <ul>
              {mergedOffers.map((offer) => (
                <li key={offer.id}>
                  <img src={offer.iconUrl} alt={offer.name} />
                </li>
              ))}
            </ul>

            <div className={Styles.lMainMissionCoin}>
              <p style={{ color: recommendPopupColor.pointNumberColor }}>
                {totalUserOfferPoint.toLocaleString()}
                <span>{mediaData.pointUnit}GET!</span>
              </p>
            </div>
          </div>

          <div
            className={Styles.lMainCase}
            style={{ backgroundColor: recommendPopupColor.offerListBgColor }}
          >
            <h3 style={{ color: recommendPopupColor.recommendTextColor }}> {RecommendText} </h3>

            <ul>
              {filteredOffers.map((offer) => (
                <li
                  key={offer.id}
                  className={Styles.lMainCaseItem}
                  style={{ backgroundColor: color.appAreaBgColor }}
                >
                  <Link
                    to={`${defaultPath.INDEX}/detail/${locationQuery}&offer=${offer.id}&linkfrom=ow_recommend`}
                    className={Styles.lMainCaseLink}
                    aria-label={offer.displayName}
                  />

                  <div className={Styles.lMainCaseLeft}>
                    <img src={offer.iconUrl} alt={offer.displayName} />
                  </div>
                  <div className={Styles.lMainCaseRight}>
                    <div className={Styles.lMainCaseText}>
                      <h4 style={{ color: color.appAreaTxtColor }}>{offer.displayName}</h4>
                      <p style={{ color: color.cvConditionColor }}>{offer.cvCondition}</p>
                    </div>
                    <div className={Styles.lMainCaseCoin}>
                      <div className={Styles.lMainCaseCoinAfter}>
                        {offer.isSur && (
                          <span className={Styles.maxLabel} style={{ color: "black" }}>
                            MAX
                          </span>
                        )}
                        <img src={mediaData.pointIconUrl} alt="Coin" />
                        <span className={Styles.coinValue} style={{ color: color.appAreaTxtColor }}>
                          {offer.conversionPoints.reduce(
                            (total, point) => total + (point.bonus ? point.bonus.point : 0),
                            offer.actualPoint
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
