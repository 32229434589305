import { useEffect, useRef, useState } from "react";
import defaultPath from "utiles/path";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import Styles from "stylesheets/index/OfferTile.module.scss";
import HorizontalListStyles from "stylesheets/index/HorizontalOffersList.module.scss";
import stylesResponsive from "stylesheets/index/OffersListItem.module.scss";
import stylesPortrait from "stylesheets/index/OffersListItemPortrait.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/slick.scss";
import { Button } from "components/common/Button";
import { PointResult, Props as PointResultProps } from "components/index/PointResult";
import { Clock } from "components/shared/Clock";
import { Link } from "react-router-dom";
import clsx from "clsx";
import DlButtonName from "components/common/dlButtonName";
import { linkFromType } from "types/linkFromType";
import { offerType } from "utiles/media";
import { offerwallType } from "types/offerwallType";
import TutorialOfferWrapper from "SkyflagFrontendOfferwall/components/index/TutorialOfferWrapper";
import useLandscape from "hooks/useLandscape";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { formatComma } from "utiles/formatComma";

interface Color {
  appAreaBgColor: string;
  expointTxtColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}

interface Search {
  offerId: number;
  position: number;
  linkFrom: linkFromType;
}

interface TutorialOffer {
  isTutorialOffer: boolean;
  tutorialOfferActionUrl: string;
}

export interface Props extends Omit<PointResultProps, "coinStyle" | "color"> {
  color: Color;
  pointIconUrl: string;
  videoIconUrl?: string;
  isVideo: boolean;
  isButton?: boolean;
  dlBtnName: string;
  displayName: string;
  cvCondition: string;
  listImage: string;
  search: Search;
  isPercentage?: boolean;
  isPickUp: boolean;
  pointUnit: string;
  offerwallType: offerwallType;
  tutorialOffer: TutorialOffer;
}

export const OfferCard = ({
  color,
  pointIconUrl,
  videoIconUrl,
  isVideo,
  isButton,
  dlBtnName,
  displayName,
  cvCondition,
  listImage,
  search,
  isPercentage,
  isPickUp,
  pointUnit,
  offerwallType,
  tutorialOffer,
  ...pointResultProps
}: Props) => {
  const { location } = useSelector(selectRouter);
  const { timeSale, bonus, superSale } = pointResultProps;
  const queries = new URLSearchParams(window.location.search);
  const ListStyles = offerwallType === offerType.TRIP_MILE ? stylesPortrait : stylesResponsive;
  const isLandscape = useLandscape();

  // 注目タブのボタンの表示が崩れないようにwidthを動的に生成
  const videoIconRef = useRef(null);
  const videoAdjustWidth = 2;
  const videoIconWidth = videoIconRef?.current?.clientWidth
    ? videoIconRef?.current?.clientWidth + videoAdjustWidth
    : 0;
  const buttonWidth = 102;
  const buttonMargin = 4;
  const descriptionWidth = videoIconWidth + buttonWidth + buttonMargin;

  const [, setToggle] = useState(false); // 状態の値自体は使用しないstateを更新するためのダミー
  // 動的なスタイル変更時に再レンダリングを行うためのuseEffect
  useEffect(() => {
    if (videoIconRef.current) {
      const handleLoad = () => {
        setToggle((prev) => !prev); // 前の状態を反転させる
      };
      videoIconRef.current.addEventListener("load", handleLoad);

      // クリーンアップ関数
      return () => {
        if (videoIconRef.current) {
          videoIconRef.current.removeEventListener("load", handleLoad);
        }
      };
    }

    return () => {};
  }, [videoIconRef.current]);

  return (
    <TutorialOfferWrapper
      isTutorial={tutorialOffer.isTutorialOffer && !isLandscape}
      tutorialOfferActionUrl={tutorialOffer.tutorialOfferActionUrl}
      pointProps={{
        pointUnit,
      }}
      viewType="top"
      isBigImage
    >
      <div className={Styles.wrapper} data-position={search.position}>
        <Link
          to={{
            pathname: `${defaultPath.INDEX}/detail`,
            search: new URLSearchParams({
              ...Object.fromEntries(queries.entries()),
              offer: String(search.offerId),
              position: String(search.position),
              linkfrom: String(search.linkFrom),
            }).toString(),
            state: { previousPath: "", referrer: location },
          }}
          className={Styles.link}
          data-testid="offerTileLink"
        >
          {listImage && (
            <img
              src={listImage}
              alt="バナー"
              style={{ background: color.appAreaBgColor }}
              className={Styles.image}
            />
          )}
          <div
            className={Styles.descriptionWrapper}
            style={{
              backgroundColor: color.appAreaBgColor,
              padding: isPickUp ? "6px 16px 0px" : "6px 16px",
            }}
            data-testid="offerTileDescriptionWrapper"
          >
            <div
              className={Styles.description}
              style={{ width: `calc(100%  - ${descriptionWidth}px)` }}
            >
              <div className={ListStyles.clock}>
                <Clock
                  saleType="time"
                  txtColor={color.expointTxtColor}
                  saleStart={timeSale?.startAt}
                  saleEnd={timeSale?.endAt}
                  bonusStart={bonus?.startAt}
                  bonusEnd={bonus?.endAt}
                  superSaleSpecifiedOfferStart={
                    superSale?.isSpecifiedOffer ? superSale?.startAt : null
                  }
                  superSaleSpecifiedOfferEnd={superSale?.isSpecifiedOffer ? superSale?.endAt : null}
                  isDisplaySmall
                />
              </div>
              <div className={Styles.title} style={{ color: color.appAreaTxtColor }}>
                {displayName}
              </div>
              <div className={Styles.offerTileContainer}>
                {cvCondition && (
                  <p
                    className={clsx(Styles.offerTileType, Styles.title)}
                    style={{ color: color.cvConditionColor }}
                  >
                    {cvCondition}
                  </p>
                )}
              </div>
            </div>
            <div className={Styles.iconAndButton}>
              {isVideo && videoIconUrl && (
                <img
                  ref={videoIconRef}
                  src={videoIconUrl}
                  alt="ビデオアイコン"
                  style={{ background: color.appAreaBgColor }}
                  className={Styles.videoImage}
                />
              )}
              {isButton && (
                <Button
                  title={
                    <DlButtonName
                      pointIconUrl={pointIconUrl}
                      dlBtnName={dlBtnName}
                      isPercentage={isPercentage}
                    />
                  }
                  className={Styles.offerTileButton}
                  color={{
                    bgColor: color.dlBtnBgColor,
                    txtColor: color.dlBtnTxtColor,
                  }}
                  tag="button"
                  type="primary"
                  isPortraitFixed={offerwallType === offerType.TRIP_MILE}
                />
              )}
            </div>
          </div>
          <div
            style={{
              backgroundColor: color.appAreaBgColor,
              display: "flex",
              padding: "0px 16px 6px 16px",
              fontFamily: "Helvetica",
              fontWeight: "700",
            }}
          >
            <PointResult
              color={{
                appAreaTxtColor: color.appAreaTxtColor,
              }}
              pointIconUrl={pointIconUrl}
              coinStyle={HorizontalListStyles.coin}
              {...pointResultProps}
            />
            {bonus?.point > 0 && (
              <span
                className={clsx(FontStyles.bold, FontStyles.mediumLarge)}
                style={{ color: color.appAreaTxtColor }}
              >
                +{formatComma(bonus.point)}
              </span>
            )}
            {isPickUp && (
              <span
                className={Styles.pointUnit}
                style={{
                  color: color.appAreaTxtColor,
                  alignContent: "center",
                  marginLeft: "3px",
                  marginTop: "2px",
                }}
              >
                {pointUnit}
              </span>
            )}
          </div>
        </Link>
      </div>
    </TutorialOfferWrapper>
  );
};
