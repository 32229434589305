import React from "react";
import { OfferIcon } from "components/shared/OfferIcon";
import { Clock } from "components/shared/Clock";
import Styles from "stylesheets/common/Card.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import PointResultStyles from "stylesheets/index/PointResult.module.scss";
import { PointResult } from "components/index/PointResult";
import clsx from "clsx";
import { formatComma } from "utiles/formatComma";

interface Color {
  appAreaTxtColor?: string;
  appAreaBgColor?: string;
  mcvTxtColor?: string;
  cvConditionColor?: string;
  clockTxtColor?: string;
}

interface TimeSale {
  startAt: Date;
  endAt: Date;
}
interface Bonus {
  point: number;
  startAt: Date;
  endAt: Date;
}
interface SuperSale {
  startAt: Date;
  endAt: Date;
  isSpecifiedOffer?: boolean;
}

export interface Props {
  position: number;
  color: Color;
  recommendIconUrl?: string;
  isVideo: boolean;
  videoIconUrl?: string;
  isSur: boolean;
  iconUrl: string;
  title: string;
  cvCondition?: string;
  isClock?: boolean;
  text: string;
  type?: "default" | "campaign";
  isPrimaryPoint?: boolean;
  timeSale?: TimeSale;
  bonus?: Bonus;
  superSale?: SuperSale;
  pointIconUrl: string;
  pointUnit: string;
  basePoint: number;
  actualPoint: number;
  firstConversionPointBasePoint: number;
  firstConversionPointActualPoint: number;
  isIndex?: boolean;
  isPercentage?: boolean;
  isPickUp?: boolean;
  isLast: boolean;
  isMultiConversionSurvey: boolean;
}

export const OfferListItem = React.memo(
  ({
    position,
    color,
    text,
    recommendIconUrl,
    isVideo = true,
    videoIconUrl,
    isSur,
    iconUrl,
    title,
    cvCondition,
    isClock = false,
    type = "default",
    isPrimaryPoint,
    timeSale,
    bonus,
    superSale,
    pointIconUrl,
    pointUnit,
    basePoint,
    actualPoint,
    firstConversionPointBasePoint,
    firstConversionPointActualPoint,
    isIndex,
    isPercentage,
    isPickUp,
    isLast,
    isMultiConversionSurvey,
  }: Props) => (
    <div
      className={isLast ? Styles.wrapper : Styles.wrapper_notlast}
      style={{ backgroundColor: color.appAreaBgColor }}
      data-position={position}
    >
      <div className={Styles.offer}>
        <OfferIcon src={iconUrl} />
        <div className={Styles.offer_content}>
          <div className={Styles.info}>
            {type === "default" && (
              <div className={Styles.clock}>
                {isClock && (
                  <Clock
                    saleStart={timeSale?.startAt}
                    saleEnd={timeSale?.endAt}
                    bonusStart={bonus?.startAt}
                    bonusEnd={bonus?.endAt}
                    superSaleSpecifiedOfferStart={
                      superSale?.isSpecifiedOffer ? superSale?.startAt : null
                    }
                    superSaleSpecifiedOfferEnd={
                      superSale?.isSpecifiedOffer ? superSale?.endAt : null
                    }
                    saleType="time"
                    isDisplaySmall
                    txtColor={color.clockTxtColor}
                  />
                )}
              </div>
            )}
            <h3 className={Styles.info_name} style={{ color: color.appAreaTxtColor }}>
              {title}
            </h3>
            <div
              className={clsx(Styles.info_cv, FontStyles.tiny)}
              style={{ color: color.cvConditionColor }}
            >
              {cvCondition}
            </div>
            <div className={Styles.detail}>
              {text && (
                <div className={clsx(Styles["offer-type-txt"], isSur && Styles.sur)}>
                  <span style={{ color: color.mcvTxtColor }} data-testid="surText">
                    {text}
                  </span>
                </div>
              )}
              <div
                data-testid="result"
                className={clsx(Styles.point, type === "campaign" && Styles["campaign-point"])}
              >
                {type === "campaign" && isPrimaryPoint ? (
                  <>
                    <span className={clsx(PointResultStyles.before, FontStyles.bold)}>
                      {formatComma(basePoint)}
                      {isPercentage && "%分の"}
                      {pointUnit}
                    </span>
                    {pointIconUrl && (
                      <img
                        style={{ width: "16px", height: "16px", marginRight: "4px" }}
                        src={pointIconUrl}
                        alt="ポイントアイコン"
                      />
                    )}
                    <span
                      className={clsx(PointResultStyles.after, FontStyles.bold)}
                      style={{ color: color.appAreaTxtColor }}
                    >
                      {formatComma(actualPoint)}
                      {isPercentage ? (
                        <>
                          %分の{pointUnit}
                          {bonus?.point > 0 && (
                            <>
                              +{formatComma(bonus.point)}
                              {pointUnit}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {bonus?.point > 0 ? (
                            <>
                              +{formatComma(bonus.point)}
                              {pointUnit}
                            </>
                          ) : (
                            pointUnit
                          )}
                        </>
                      )}
                    </span>
                  </>
                ) : (
                  type === "campaign" && (
                    <>
                      {pointIconUrl && (
                        <img
                          src={pointIconUrl}
                          className={CommonStyles.pointIcon}
                          alt="ポイントアイコン"
                        />
                      )}
                      <div
                        className={clsx(FontStyles.bold)}
                        style={{ color: color.appAreaTxtColor }}
                      >
                        {formatComma(actualPoint)}
                        {isPercentage ? (
                          <>
                            %分の{pointUnit}
                            {bonus?.point > 0 && (
                              <>
                                +{formatComma(bonus.point)}
                                {pointUnit}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {bonus?.point > 0 ? (
                              <>
                                +{formatComma(bonus.point)}
                                {pointUnit}
                              </>
                            ) : (
                              pointUnit
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
          {type === "default" && recommendIconUrl ? (
            <div className={Styles.recommendIcon}>
              <img src={recommendIconUrl} alt="オススメ" />
              <div className={Styles.pointSpace}>
                <PointResult
                  color={{
                    appAreaTxtColor: color.appAreaTxtColor,
                  }}
                  coinStyle={Styles.coin}
                  isSur={isSur}
                  timeSale={timeSale}
                  bonus={bonus}
                  superSale={superSale}
                  pointIconUrl={pointIconUrl}
                  basePoint={basePoint}
                  actualPoint={actualPoint}
                  firstConversionPointBasePoint={firstConversionPointBasePoint}
                  firstConversionPointActualPoint={firstConversionPointActualPoint}
                  isIndex={isIndex}
                  isPercentage={isPercentage}
                  isMultiConversionSurvey={isMultiConversionSurvey}
                />
                <div style={{ color: color.appAreaTxtColor }}>
                  {isPercentage && `分の${pointUnit}`}
                  {bonus?.point > 0 && (
                    <span className={clsx(FontStyles.bold, FontStyles.mediumLarge)}>
                      +{formatComma(bonus.point)}
                    </span>
                  )}
                  {isPickUp && !isPercentage && (
                    <span className={Styles.pointUnit} style={{ color: color.appAreaTxtColor }}>
                      {pointUnit}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : type === "default" && isVideo && videoIconUrl ? (
            <div className={Styles.recommendIcon}>
              <img src={videoIconUrl} alt="ビデオ" />
              <div className={Styles.pointSpace}>
                <PointResult
                  color={{
                    appAreaTxtColor: color.appAreaTxtColor,
                  }}
                  coinStyle={Styles.coin}
                  isSur={isSur}
                  timeSale={timeSale}
                  bonus={bonus}
                  superSale={superSale}
                  pointIconUrl={pointIconUrl}
                  basePoint={basePoint}
                  actualPoint={actualPoint}
                  isIndex={isIndex}
                  isPercentage={isPercentage}
                />
                <div style={{ color: color.appAreaTxtColor }}>
                  {isPercentage && `分の${pointUnit}`}
                  {bonus?.point > 0 && (
                    <span className={clsx(FontStyles.bold, FontStyles.mediumLarge)}>
                      +{formatComma(bonus.point)}
                    </span>
                  )}
                  {isPickUp && !isPercentage && (
                    <span className={Styles.pointUnit} style={{ color: color.appAreaTxtColor }}>
                      {pointUnit}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            type === "default" && (
              <div className={Styles.pointSpace}>
                <PointResult
                  color={{
                    appAreaTxtColor: color.appAreaTxtColor,
                  }}
                  coinStyle={Styles.coin}
                  isSur={isSur}
                  timeSale={timeSale}
                  bonus={bonus}
                  superSale={superSale}
                  pointIconUrl={pointIconUrl}
                  basePoint={basePoint}
                  actualPoint={actualPoint}
                  firstConversionPointBasePoint={firstConversionPointBasePoint}
                  firstConversionPointActualPoint={firstConversionPointActualPoint}
                  isIndex={isIndex}
                  isPercentage={isPercentage}
                  isMultiConversionSurvey={isMultiConversionSurvey}
                />
                <div style={{ color: color.appAreaTxtColor }}>
                  {isPercentage && `分の${pointUnit}`}
                  {bonus?.point > 0 && (
                    <span
                      className={clsx(FontStyles.bold, FontStyles.mediumLarge)}
                      data-testid="result"
                    >
                      +{formatComma(bonus.point)}
                    </span>
                  )}
                  {isPickUp && !isPercentage && (
                    <span className={Styles.pointUnit} style={{ color: color.appAreaTxtColor }}>
                      {pointUnit}
                    </span>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
);
