import { useEffect, useState } from "react";
import { offerApi } from "apis/offer";
import { History } from "models";
import { plainToClass } from "class-transformer";
import { selectMediaState } from "store/selector";
import { useSelector } from "react-redux";
import { removeEmpty } from "modules/removeEmpty";
import { dummyApi } from "apis/dummyApi";

export const useHistory = (): [
  boolean,
  boolean,
  History[] | undefined,
  (offset: number) => Promise<History[]>
] => {
  const media = useSelector(selectMediaState);
  const queries = new URLSearchParams(window.location.search);
  const suid = queries.get("suid") ?? "";
  const [offersData, setOffersData] = useState<History[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchPaginate = async (offset: number): Promise<History[]> => {
    const res = await dummyApi.getPagenationToOffer(offersData, offset);
    return res;
  };

  // muidがないときは空データを返す
  if (suid === null) return [null, true, [], fetchPaginate];

  useEffect(() => {
    if (!media?.id) return undefined;
    // indexでタブを移動するたびに複数のapiを叩いていたのでそれの回避
    if (suid === null) {
      setIsError(false);
      setIsLoaded(true);
    } else {
      offerApi
        .getHistory<History>(
          removeEmpty(
            new URLSearchParams({
              mediaUserId: suid,
              mediaId: String(media.id),
              ...Object.fromEntries(queries),
            })
          )
        )
        .then((result) => {
          setOffersData(result.map((data) => plainToClass(History, data)));
          setIsLoaded(true);
        })
        .catch(() => {
          setIsError(true);
          setIsLoaded(true);
        });
    }
    return () => {
      setOffersData([]);
      setIsError(false);
      setIsLoaded(false);
    };
  }, [media.id]);

  return [isError, isLoaded, offersData, fetchPaginate];
};
