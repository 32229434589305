import { Expose, Type } from "class-transformer";
import { ConversionPoints, Images, Descriptions } from "models";
import { DESCRIPTION_TYPE } from "types/DescriptionType";
import { IMAGE_TYPE } from "types/ImageType";

export class Offer {
  id: number;

  @Expose({ name: "name" })
  name: string;

  @Expose({ name: "acquireDeadline" })
  acquireDeadline?: string;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "actionUrl" })
  actionUrl: string;

  @Expose({ name: "descriptions" })
  descriptions: Descriptions[];

  @Expose({ name: "displayName" })
  displayName: string;

  @Type(() => Images)
  images?: Images[];

  @Expose({ name: "advertisementId" })
  advertisementId?: number;

  @Expose({ name: "isRecommended" })
  isRecommended: boolean;

  @Expose({ name: "isFree" })
  isFree: boolean;

  @Expose({ name: "isEasy" })
  isEasy: boolean;

  @Expose({ name: "isBulk" })
  isBulk: boolean;

  @Expose({ name: "isVideo" })
  isVideo: boolean;

  @Expose({ name: "cvEstimatedTime" })
  cvEstimatedTime?: string;

  @Type(() => ConversionPoints)
  conversionPoints?: ConversionPoints[];

  @Expose({ name: "isIdfaRequired" })
  isIdfaRequired: boolean;

  @Expose({ name: "isPercentage" })
  isPercentage: boolean;

  @Expose({ name: "isTutorialOffer" })
  isTutorialOffer: boolean;

  @Expose({ name: "tutorialOfferActionUrl" })
  tutorialOfferActionUrl: string;

  @Expose({ name: "isSurvey" })
  isSurvey: boolean;

  @Expose({ name: "isOfferwallOfferSurvey" })
  isOfferwallOfferSurvey: boolean;

  get cvCondition() {
    return this.descriptions?.find((data) => data.type === DESCRIPTION_TYPE.CV_CONDITION)?.text;
  }

  get iconUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.ICON_URL)?.url;
  }

  get listImageUrl() {
    return this.images?.find((data) => data.type === IMAGE_TYPE.LISTIMAGE_URL)?.url;
  }

  get isSur() {
    return this.conversionPoints?.length > 1;
  }

  get isMultiConversionSurvey() {
    return this.isOfferwallOfferSurvey && this.conversionPoints?.length > 1;
  }
}
